body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    max-width: 100%;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline {
    margin: 20px;
    margin-bottom: 60px;
}

.timeline .timeline-months {
    display: flex;
    padding-left: 30px;
}

.timeline .timeline-months-month {
    width: 10px;
    margin: 2px;
    border: 1px solid transparent;
    font-size: 10px;
}

.timeline .timeline-months .Jan ~ .Jan, .timeline .timeline-months .Feb ~ .Feb, .timeline .timeline-months .Mar ~ .Mar, .timeline .timeline-months .Apr ~ .Apr, .timeline .timeline-months .May ~ .May, .timeline .timeline-months .Jun ~ .Jun, .timeline .timeline-months .Jul ~ .Jul, .timeline .timeline-months .Aug ~ .Aug, .timeline .timeline-months .Sep ~ .Sep, .timeline .timeline-months .Oct ~ .Oct, .timeline .timeline-months .Nov ~ .Nov, .timeline .timeline-months .Dec ~ .Dec {
    visibility: hidden;
}

.timeline-body {
    display: flex;
}

.timeline-body .timeline-weekdays {
    display: inline-flex;
    flex-direction: column;
    width: 30px;
}

.timeline-body .timeline-weekdays-weekday {
    font-size: 10px;
    height: 10px;
    border: 1px solid transparent;
    margin: 2px;
    vertical-align: middle;
}

.timeline-body .timeline-cells {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100px;
}

.timeline-body .timeline-cells-cell {
    height: 10px;
    width: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 2px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.05);
}

.timeline-body .timeline-cells-cell:hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
}

